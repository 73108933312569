<template>
  <v-card flat color="transparent">
    <v-card-title class="text-h5">Şifremi Unuttum?</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <phone-input
            v-model="$v.forgetForm.userName.$model"
            v-bind:properties="{
              inputmode: 'numeric',
              prefix: '+90',
              dense: true,
              hideDetails: 'auto',
              placeholder: 'Telefon numaranız',
              height: '40',
              errorMessages: userNameErrors(),
            }"
            v-bind:options="{
              humanMask: '(###)-###-##-##',
              machineMask: '###########',
              empty: null,
              applyAfter: false,
              alphanumeric: true,
              lowerCase: false,
            }"
          ></phone-input>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn class="text-capitalize rounded-lg" color="primary" block height="45" @click="forgetPassword">
        Devam et
      </v-btn>
    </v-card-actions>
    <v-card-text class="d-flex justify-center align-center">
      <div class="mr-2">Şifremi hatrladım.</div>
      <router-link class="text-capitalize" depressed text :to="{ name: 'login' }"> Giriş yapacağım</router-link>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FORGET } from '@/store/modules/auth.module';
import PhoneInput from '@/components/PhoneInput.vue';

import { validationMixin } from 'vuelidate';
import validations from './_validations';

export default {
  name: 'Index',
  mixins: [validationMixin, validations],
  components: { PhoneInput },
  data() {
    return {
      forgetForm: {
        userName: null,
      },
    };
  },
  computed: {
    ...mapGetters(['smsResponse']),
  },
  created() {
    this.$nextTick(() => {});
  },
  methods: {
    ...mapActions({
      forget: FORGET,
    }),
    forgetPassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const payload = `90${this.forgetForm.userName}`;

      this.forget(payload).then(() => {
        this.$router.push({ name: 'forgetApprove' });
      });
    },
  },
};
</script>

<style></style>
