/* eslint-disable no-unused-expressions */
import { required, helpers } from 'vuelidate/lib/validators';
const phoneCheck = helpers.regex('phoneCheck', /^(\d{3})(\d{3})(\d{2})(\d{2})$/);
const forgetValidations = {
  validations: {
    forgetForm: {
      userName: {
        required,
        phoneCheck,
      },
    },
  },
  methods: {
    userNameErrors() {
      const errors = [];
      const { $dirty, required, phoneCheck } = this.$v.forgetForm.userName;
      if (!$dirty) return errors;
      !required && errors.push('Bu bilgi gereklidir.');
      !phoneCheck && errors.push(`Lütfen geçerli bir telefon numarası giriniz.`);
      return errors;
    },
  },
};

export default forgetValidations;
