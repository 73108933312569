var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Şifremi Unuttum?")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('phone-input',{attrs:{"properties":{
            inputmode: 'numeric',
            prefix: '+90',
            dense: true,
            hideDetails: 'auto',
            placeholder: 'Telefon numaranız',
            height: '40',
            errorMessages: _vm.userNameErrors(),
          },"options":{
            humanMask: '(###)-###-##-##',
            machineMask: '###########',
            empty: null,
            applyAfter: false,
            alphanumeric: true,
            lowerCase: false,
          }},model:{value:(_vm.$v.forgetForm.userName.$model),callback:function ($$v) {_vm.$set(_vm.$v.forgetForm.userName, "$model", $$v)},expression:"$v.forgetForm.userName.$model"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"color":"primary","block":"","height":"45"},on:{"click":_vm.forgetPassword}},[_vm._v(" Devam et ")])],1),_c('v-card-text',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"mr-2"},[_vm._v("Şifremi hatrladım.")]),_c('router-link',{staticClass:"text-capitalize",attrs:{"depressed":"","text":"","to":{ name: 'login' }}},[_vm._v(" Giriş yapacağım")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }